import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import {
    Typography, Box, Button, Grid, Alert, MenuItem, Snackbar, Divider,
} from '@mui/material';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { InlineWidget } from 'react-calendly';
import Layout from '../layout/Layout';
import colors from '../theme/colors';

const darkVioletContainer = {
    minHeight: '1018px',
    background: colors.darkViolet,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    padding: '165px 0px 80px',
};
const violetContainer = {
    minHeight: '446px',
    background: colors.violet,
    display: 'flex',
    justifyContent: 'center',
};
const formWrapper = {
    background: colors.burgundy,
    minHeight: '447px,',
    bottom: '70px',
    padding: '40px 58px 30px 58px',
    maxWidth: '1168px',
    width: '100%',
    borderRadius: '10px',
    position: 'relative',
};
const customTextStyle = {
    fontFamily: 'Nunito',
    fontSize: '22px',
    fontWeight: 400,
    lineHeight: '31px',
};
const customButton = {
    height: '48px',
    width: '234px',
};
const customInput = {
    '& .MuiInputLabel-root': {
        color: colors.green,
        '&.Mui-focused': {
            color: colors.green,
        },
    },
    '& label.Mui-focused': {
        color: colors.green,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: colors.green,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: colors.green,
        },
        '&:hover fieldset': {
            borderColor: colors.green,
        },
        '&.Mui-focused fieldset': {
            borderColor: colors.green,
        },

    },
    'input:-webkit-autofill': {
        WebkitBoxShadow: `0 0 0px 30px ${colors.violet} inset !important`,
        WebkitTextFillColor: `${colors.lightGreen} !important`,
    },
};

const initialContactInfoValue = {
    title: '',
    name: '',
    company: '',
    message: '',
    email: '',
    phone: '',
    toSend: '',
};

const toSendConstants = {
    SUPPORT: 'soporte@nubi2go.com',
    ENGINEERING: 'ingenieria@nubi2go.com',
    COMMERCIAL: 'ventas@nubi2go.com',
};

const ContactUs = () => {
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const [contactInfo, setContactInfo] = useState(initialContactInfoValue);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContactInfo({
            ...contactInfo,
            [name]: value,
        });
    };

    const sendEmail = (e) => {
        emailjs.init(process.env.GATSBY_EMAILJS_USER_ID);
        e.preventDefault();
        emailjs.send('service_pzwo8iz', 'template_nubi2go', contactInfo)
            .then(() => {
                setContactInfo(initialContactInfoValue);
                setOpenSnackbar(true);
            });
    };

    return (
        <Layout>
            <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { padding: '133px 24px 80px' }, ...darkVioletContainer })}>

                    <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { margin: '20px' }, ...formWrapper })}>
                        <ValidatorForm
                            onSubmit={sendEmail}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <Box mb={3}>
                                        <TextValidator
                                            label="Nombre completo"
                                            onChange={(e) => handleChange(e)}
                                            value={contactInfo.name}
                                            name="name"
                                            placeholder="Nombre completo"
                                            validators={['required']}
                                            errorMessages={['Este campo es requerido']}
                                            fullWidth
                                            sx={{ ...customInput }}
                                        />
                                    </Box>
                                    <Box mb={3}>
                                        <TextValidator
                                            label="Empresa"
                                            onChange={(e) => handleChange(e)}
                                            value={contactInfo.company}
                                            name="company"
                                            placeholder="Empresa"
                                            fullWidth
                                            sx={{ ...customInput }}
                                        />
                                    </Box>
                                    <Box mb={3}>
                                        <TextValidator
                                            label="Email"
                                            onChange={(e) => handleChange(e)}
                                            name="email"
                                            value={contactInfo.email}
                                            placeholder="Email"
                                            validators={['required', 'isEmail']}
                                            errorMessages={['Este campo es requerido', 'El email no es válido']}
                                            fullWidth
                                            sx={{ ...customInput }}
                                        />
                                    </Box>
                                    <Box mb={3}>
                                        <TextValidator
                                            label="Teléfono"
                                            onChange={(e) => handleChange(e)}
                                            name="phone"
                                            type="text"
                                            value={contactInfo.phone}
                                            placeholder="Teléfono"
                                            validators={['required']}
                                            errorMessages={['Este campo es requerido']}
                                            fullWidth
                                            sx={{ ...customInput }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            pr={2}
                                            sx={(theme) => ({ [theme.breakpoints.down('md')]: { padding: 0 } })}
                                        >
                                            <Box mb={3}>
                                                <TextValidator
                                                    label="Título de la consulta"
                                                    onChange={(e) => handleChange(e)}
                                                    name="title"
                                                    value={contactInfo.title}
                                                    placeholder="Título de la consulta"
                                                    validators={['required']}
                                                    errorMessages={['Este campo es requerido']}
                                                    fullWidth
                                                    sx={{ ...customInput }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            pl={1}
                                            sx={(theme) => ({ [theme.breakpoints.down('md')]: { padding: 0 } })}
                                        >
                                            <Box mb={3}>
                                                <SelectValidator
                                                    label="Enviar a"
                                                    onChange={(e) => handleChange(e)}
                                                    name="toSend"
                                                    value={contactInfo.toSend}
                                                    placeholder="Enviar a"
                                                    validators={['required']}
                                                    errorMessages={['Este campo es requerido']}
                                                    fullWidth
                                                    sx={{ ...customInput }}
                                                >
                                                    <MenuItem
                                                        value={toSendConstants.SUPPORT}
                                                    >
                                                        Equipo de Soporte
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={toSendConstants.ENGINEERING}
                                                    >
                                                        Equipo de Ingeniería
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={toSendConstants.COMMERCIAL}
                                                    >
                                                        Equipo Comercial
                                                    </MenuItem>
                                                </SelectValidator>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                label="Escriba aquí su consulta"
                                                onChange={(e) => handleChange(e)}
                                                multiline
                                                minRows={8}
                                                name="message"
                                                value={contactInfo.message}
                                                placeholder="Escriba aquí su consulta..."
                                                validators={['required']}
                                                errorMessages={['Este campo es inválido']}
                                                fullWidth
                                                sx={{ ...customInput }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box style={{ display: 'flex', justifyContent: 'end' }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            aria-label="Enviar"
                                            sx={{ ...customButton }}
                                        >
                                            Enviar consulta
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                        <Snackbar
                            open={openSnackBar}
                            autoHideDuration={6000}
                            onClose={handleCloseSnackBar}
                        >
                            <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>Mensaje enviado con éxito!</Alert>
                        </Snackbar>
                    </Box>
            </Box>
        </Layout>
    );
};

export default ContactUs;
